<template>
  <div class="campaign-reporting">
    <div class="campaign-reporting__header">
      <portal-target name="campaign-reporting-earnings" class="campaign-reporting__header-earnings" />
    </div>
    <section class="campaign-reporting__chart-filters">
      <date-picker-filter
        :value="dateOptions"
        :hide-date-picker-input="true"
        @input="handleDateRangeChange" />
      <campaign-export-button :disabled="firstLoad || noCsvData" :active-date-options="dateOptions" :campaign-id="campaignId" />
    </section>
    <section class="campaign-reporting__visualization">
      <reporting-overview :campaign-id="campaignId" />
    </section>
    <section v-if="showTable" class="campaign-reporting__table">
      <reporting-table :campaign-id="campaignId" @campaign-details-change="handleCampaignDetailsChange" />
    </section>
  </div>
</template>

<script>
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import CampaignExportButton from '@/components/campaigns/CampaignExportButton';
import ReportingOverview from '@/sections/ReportingOverview';
import ReportingTable from '@/sections/ReportingTable';

export default {
  name: 'CampaignReporting',
  components: {
    ReportingOverview,
    ReportingTable,
    DatePickerFilter,
    CampaignExportButton
  },
  props: {
    showTable: {
      Type: Boolean,
      default: true
    }
  },
  data() {
    return {
      firstLoad: true,
      noCsvData: true
    };
  },
  computed: {
    campaign() {
      return this.$store.getters.campaign(this.$route.params.id);
    },
    campaignId() {
      return this.$route.params.id;
    },
    dateOptions() {
      return this.$store.getters.getMetricDateOptions;
    }
  },
  methods: {
    handleCampaignDetailsChange(firstLoad, noCsvData) {
      this.firstLoad = firstLoad;
      this.noCsvData = noCsvData;
    },
    handleDateRangeChange(newDate) {
      this.$store.dispatch('setMetricDateRange', newDate);
    }
  }
};
</script>

<style lang="scss">
.campaign-reporting {
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    h3 {
      color: black;
    }

    &-right {
      &-title {
        font-size: 16px;
        font-weight: 400;
      }

      &-body {
        color: $--clb-body-font;
        font-size: 20px;
        font-weight: bold;
        padding-left: $--clb-space-2;
      }
    }
  }

  &__chart-filters {
    display: flex;
    flex-direction: column;
    margin: $--clb-space-4 0;
    flex-shrink: 0;

    .date-picker-filter {
      width: 100%;
      margin-bottom: $--clb-layout-2;

      &__tabs {
        background: $--clb-color-primary__white;
        border: 1px solid #ececec;
      }
    }
  }

  .export-button {
    width: 100%;

    .el-button-group {
      .el-button:first-child {
        width: calc(100% - 50px);
      }

      .el-button:last-child {
        width: 50px;
      }
    }
  }
}

@media (min-width: $--sm) {
  .campaign-reporting {
    .date-picker-filter {
      width: 350px;
      margin: 0;
    }

    &__chart-filters {
      margin: $--clb-layout-3 0;
      justify-content: space-between;
      flex-direction: row;

      .dashboard-filter {
        width: 350px;
        margin-bottom: 0;
      }
    }

    .export-button {
      width: auto;

      .el-button-group {
        .el-button:first-child {
          width: auto;
        }
      }
    }

    .campaign-reporting__table {
      margin-top: $--clb-space-3;
    }

    .campaign-navigation-header__left {
      white-space: nowrap;
    }
  }
}
</style>
