<template>
  <div class="tw-bg-white tw-border tw-rounded tw-p-space-4 tw-w-full">
    <div class="tw-flex tw-justify-center">
      <skeleton-box height="150" width="150" />
    </div>
    <div>
      <skeleton-box class="tw-m-0 tw-mt-space-4" height="20" width="100" />
    </div>
    <div>
      <skeleton-box class="tw-m-0 tw-mt-space-1" height="15" width="60" />
    </div>
  </div>
</template>
<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignCardV2Skeleton',
  components: { SkeletonBox }
};
</script>
