<template>
  <div class="campaign-metrics">
    <div class="campaign-metrics--groupToggle">
      <ul>
        <li
          v-for="groupOption in groupBy"
          :key="`option-${groupOption}`"
          :class="{active: groupOption === activeGroupBy}"
          @click="handleGroupChange(groupOption)">
          {{ groupOption.replace('_', ' ').toLowerCase() | capitalizeAll }}
        </li>
      </ul>
    </div>
    <metrics-table
      v-scrollable:xy
      :loading="loading"
      :details="details"
      :money-label="moneyLabel"
      :active-group-by="activeGroupBy"
      :metrics="metrics" />
  </div>
</template>

<script>
import MetricsTable from '@/components/tables/MetricsTable';

const MONEY_LABEL_MAP = {
  affiliate: 'Earned',
  advertiser: 'Paid'
};

const METRICS_MAP = {
  affiliate: ['clicks', 'sales', 'conversion_rate', 'epc', 'money'],
  advertiser: ['clicks', 'sales', 'conversion_rate', 'money']
};

export default {
  name: 'CampaignMetrics',
  components: { MetricsTable },
  props: {
    details: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    orgType: {
      type: String,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      metrics: METRICS_MAP[this.userType] || METRICS_MAP.advertiser,
      activeGroupBy: 'date',
      stickyHeaderEl: null
    };
  },
  computed: {
    groupBy() {
      return Object.keys(this.details);
    },
    moneyLabel() {
      return MONEY_LABEL_MAP[this.userType] ? MONEY_LABEL_MAP[this.userType] : 'Money';
    }
  },
  methods: {
    handleGroupChange(groupOption) {
      this.activeGroupBy = groupOption;
    }
  }
};
</script>

<style lang="scss">
.campaign-metrics {
  &--groupToggle {
    background: #f3f5fd;
    padding: $--clb-space-2;
    border-radius: $--clb-border-radius;
    margin: 0 0 32px 0;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
    }

    li {
      border-radius: $--clb-border-radius;
      border: 1px solid transparent;
      margin: 0 $--clb-space-1;
      width: 100%;
      transition: all 0.3s ease;
      text-align: center;
      color: $--clb-body-font;
      font-size: $--clb-font-size-xs;
      line-height: 24px;
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active,
      &.active:hover {
        background: $--clb-btn-bg-color;
        color: $--clb-color-primary__white !important;
      }

      &:hover {
        background: #f3f5fd;
        color: $--clb-btn-bg-color;
        border: 1px solid $--clb-btn-bg-color;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
.jb-mobile-device {
  .campaign-metrics {
    li {
      transition: none;
    }
  }
}
</style>
