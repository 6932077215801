<template functional>
  <component
    :is="injections.components.AppCard"
    class="app-icon-card tw-p-layout-1 tw-pb-space-4 sm:tw-mb-0 tw-bg-white tw-border-0 tw-rounded"
    :class="!$options.computed.hasClickHandler(listeners) ? '!tw-cursor-auto !tw-transform hover:tw-translate-y-0' : ''"
    @click="$options.computed.hasClickHandler(listeners) ? listeners.click() : (() => {})">
    <template v-if="$options.computed.hasHeaderSlot(props, data)" #header>
      <slot name="header" />
    </template>

    <template v-if="$options.computed.hasIcon(props, data)">
      <slot name="icon">
        <i class="tw-border-jb-indigo tw-mb-space-2 sm:tw-mb-layout-1 sm:tw-w-10 sm:tw-h-10 sm:tw-text-2xl tw-text-jb-grey-700 tw-border-0.5 tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-xl tw-not-italic tw-font-semibold tw-border-solid tw-rounded-full" :class="!$options.computed.isIconNumeric(props) ? props.icon : ''">
          {{ $options.computed.isIconNumeric(props) ? props.icon : null }}
        </i>
      </slot>
    </template>

    <template v-if="$options.computed.hasTitle(props, data)">
      <slot name="title">
        <component
          :is="injections.components.AppHeading"
          level="h3"
          class="sm:tw-text-h4 tw-mb-space-1 tw-text-jb-ink tw-m-0 tw-text-base"
          weight="semi-bold">
          {{ props.title }}
        </component>
      </slot>
    </template>

    <template v-if="$options.computed.hasDesc(props, data)">
      <slot name="desc">
        <component
          :is="injections.components.AppText"
          size="sm"
          class="sm:tw-text-base tw-text-jb-grey-700">
          {{ props.desc }}
        </component>
      </slot>
    </template>

    <template v-if="$options.computed.hasCta(props, data)">
      <slot name="cta">
        <div class="tw-pt-layout-3 tw-pointer-events-none" />
        <component
          :is="injections.components.AppLink"
          class="tw-mt-space-2 tw-group tw-bottom-1 tw-absolute tw-right-0 tw-flex tw-text-sm tw-transition tw-transform"
          underline="none"
          weight="medium"
          @click.native.stop.prevent="$options.methods.ctaClick(listeners)">
          <span class="group-hover:tw-border-primary tw-pb-space-1 tw-border- tw-transition tw-border-b-2 tw-border-transparent tw-border-solid">{{ props.cta }}</span>
        </component>
      </slot>
    </template>

    <template v-if="$options.computed.hasFooterSlot(props, data)" #footer>
      <slot name="footer" />
    </template>
  </component>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppHeading from '@/components/AppHeading';
import AppText from '@/components/AppText';
import AppLink from '@/components/AppLink';

export default {
  name: 'AppIconCard',
  components: { AppCard, AppHeading, AppText, AppLink },
  inject: { components: { default: { AppCard, AppHeading, AppText, AppLink } } },
  props: {
    icon: {
      type: [Number, String],
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    desc: {
      type: String,
      default: undefined
    },
    cta: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasSlot(name = 'default', { scopedSlots = {} } = {}) {
      return typeof scopedSlots[name] === 'function' && !!scopedSlots[name]();
    },
    hasHeaderSlot(props, data) {
      return this.hasSlot('header', data);
    },
    hasFooterSlot(props, data) {
      return this.hasSlot('footer', data);
    },
    hasIcon(props, data) {
      return this.hasSlot('icon', data) || !!props.icon;
    },
    hasTitle(props, data) {
      return this.hasSlot('title', data) || !!props.title;
    },
    hasDesc(props, data) {
      return this.hasSlot('desc', data) || !!props.desc;
    },
    hasCta(props, data) {
      return this.hasSlot('cta', data) || !!props.cta;
    },
    isIconNumeric({ icon }) {
      return icon && !isNaN(icon);
    },
    hasClickHandler(listeners) {
      return !!listeners.click;
    }
  },
  methods: {
    ctaClick(listeners) {
      if (listeners['cta-click']) {
        listeners['cta-click']();
      } else if (listeners.click) {
        listeners.click();
      }
    }
  }
};
</script>

<style lang="scss">
.app-icon-card .app-card__body {
  @apply tw-h-full;
}
</style>
