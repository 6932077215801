export default {
  data: function() {
    return {
      showStickyHeader: false
    };
  },
  watch: {
    showStickyHeader: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.stickyHeader());
      }
    }
  },
  props: {
    stickyAppHeaderHeight: {
      type: Number,
      default: 55
    },
    stickySinkId: {
      type: String,
      default: 'table-sink'
    },
    stickySinkRef: {
      type: String,
      default: 'sink'
    },
    stickyTableRef: {
      type: String,
      default: 'table'
    }
  },
  beforeDestroy: function() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('touchmove', this.handleScroll);
  },
  mounted: function() {
    this.addChangeListener();
    window.addEventListener('scroll', this.handleScroll, {
      capture: true,
      passive: true
    });
    window.addEventListener('touchmove', this.handleScroll, {
      capture: true,
      passive: true
    });
    this.handleScroll();
  },
  methods: {
    // Sticky Header Methods
    handleScroll: function(e) {
      try {
        this.showStickyHeader = this.$refs[this.stickyTableRef].$el.offsetParent &&
        this.$refs[this.stickyTableRef].$el.getBoundingClientRect().top <= this.stickyAppHeaderHeight &&
        this.$refs[this.stickyTableRef].$el.getBoundingClientRect().bottom >= this.stickyAppHeaderHeight;
      } catch (e) {
        this.showStickyHeader = false;
      }
    },
    addChangeListener: function() {
      try {
        const ref = this.$refs[this.stickyTableRef].$refs.tableHeader;
        if (!ref) return;

        if (!ref.$options.updated.includes(this.stickyHeader)) {
          ref.$on('hook:created', this.stickyHeader);
          ref.$on('hook:updated', this.stickyHeader);
          ref.tableLayout.observers.push({
            onColumnsChange: () => this.$nextTick(() => this.stickyHeader()),
            onScrollableChange: () => this.$nextTick(() => this.stickyHeader())
          });
          ref.$el.parentNode.addEventListener('scroll', this.stickyHeaderScroll, {
            capture: true,
            passive: true
          });
          ref.$el.parentNode.addEventListener('touchmove', this.stickyHeaderScroll, {
            capture: true,
            passive: true
          });
        }
      } catch (e) { }
    },
    stickyHeader: function() {
      this.handleScroll();
      if (!this.$refs || !this.$refs[this.stickyTableRef] || !this.showStickyHeader) return;

      const ref = this.$refs[this.stickySinkRef];
      const elm = this.$refs[this.stickyTableRef].$refs.headerWrapper;
      const fixed = this.$refs[this.stickyTableRef].$refs.fixedWrapper;

      if (ref) {
        // Remove existing children
        while (ref.firstChild) {
          ref.removeChild(ref.firstChild);
        }

        // Append header
        if (elm) {
          const clone = (this.stickyHeaderEl = elm.cloneNode(true));
          ref.appendChild(clone);
        }

        if (fixed) {
          const fixedClone = fixed.cloneNode(true);
          fixedClone.removeChild(fixedClone.querySelector('.el-table__fixed-body-wrapper'));
          ref.appendChild(fixedClone);
        }

        this.stickyHeaderScroll({ target: { scrollLeft: elm.scrollLeft } });
      }
    },
    stickyHeaderScroll: function(e) {
      if (this.stickyHeaderEl) {
        this.stickyHeaderEl.scrollLeft = (e.target || e.srcElement).scrollLeft;
      }
    },
    clickDelegation: function(e) {
      let el = e.target || e.srcElement;
      let selector = '';
      while (el && el.id !== `${this.stickySinkId}`) {
        selector = this.getElementSelector(el) + ' ' + selector;
        el = el.parentNode;
      }

      const elementTable = this.$refs[this.stickyTableRef].$el;

      if (elementTable && elementTable.querySelector(selector)) {
        elementTable.querySelector(selector).click();
      }
    },
    getElementSelector: function(el) {
      return `${el.nodeName.toLowerCase()}.${
        el.className
          .replace(/\s+/g, ' ')
          .trim()
          .split(' ')[0]
      }`.replace(/\.$/, '');
    }
  }
};
