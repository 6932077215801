<template>
  <div class="app-image-grid">
    <div ref="wrapper" class="app-image-grid__container">
      <div
        v-for="(image, index) in batchedImages"
        :key="index"
        :class="{'app-image-grid__item-skeleton': showSkeletons}"
        class="app-image-grid__item">
        <div v-if="showSkeletons">
          <campaign-story-skeleton height="200" width="150" />
        </div>
        <div
          v-else
          @click="$emit('click-image', image, index)">
          <app-preview-card
            size="medium"
            height="200"
            :background-image="image.url" />
        </div>
      </div>
    </div>
    <portal to="campaign-preview-view-all">
      <div v-if="showViewMore" class="tw-flex tw-justify-center">
        <app-button text-weight="medium" class="tw-text-jb-grey-700 tw-text-sm" state="text" @click="handleViewMore">
          View All
        </app-button>
      </div>
    </portal>
  </div>
</template>

<script>
import imageService from '@/services/image-service';
import Breakpoints from '@/mixins/Breakpoints';
import AppPreviewCard from '@/components/AppPreviewCard';
import CampaignStorySkeleton from '@/components/skeletons/campaigns/CampaignStorySkeleton';
import AppButton from '@/components/AppButton';

const breakpoints = {
  0: 4,
  428: 6,
  577: 4,
  610: 4,
  725: 5,
  871: 6
};

export default {
  name: 'AppImageGrid',
  components: { AppPreviewCard, CampaignStorySkeleton, AppButton },
  mixins: [Breakpoints(Object.keys(breakpoints).map((b) => parseInt(b)), 'wrapperWidth', 'wrapperBreakpoint')],
  props: {
    images: {
      type: Array,
      required: false,
      default: () => []
    },
    showSkeletons: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      batchPage: 1,
      isLoaded: false,
      wrapperWidth: 1024
    };
  },
  computed: {
    batchSize() {
      return breakpoints[this.wrapperBreakpoint];
    },
    batchedImages() {
      return this.images
        .slice(0, this.batchSize * this.batchPage)
        .map(item => {
          return {
            url: imageService.resizeImage(item.url, {
              w: 200,
              h: 200,
              auto: 'enhance,format',
              fit: 'crop',
              crop: 'top',
              bg: 'efefef',
              fm: 'jpeg',
              q: 85
            })
          };
        });
    },
    showViewMore() {
      return this.isLoaded && this.images.length > this.batchedImages.length;
    }
  },
  watch: {
    images: {
      immediate: true,
      handler(images) {
        this.batchPage = 1;
        this.$emit('start-load');
        this.isLoaded = false;
        imageService
          .imagePreload(this.batchedImages.map(i => i.url))
          .then(() => {
            this.isLoaded = true;
            this.$emit('finish-load');
          })
          .catch(() => {
            this.isLoaded = true;
            this.$emit('finish-load');
          });
      }
    },
    '$store.getters.leftSidebarVisible': {
      immediate: true,
      handler() {
        this.$nextTick(() => this.windowResized());
      }
    }
  },
  created() {
    window.addEventListener('resize', this.windowResized, this.$supportsPassive && { passive: true });
  },
  mounted() {
    this.windowResized();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResized, this.$supportsPassive && { passive: true });
  },
  methods: {
    handleViewMore() {
      this.batchPage += 3;
    },
    windowResized() {
      this.wrapperWidth = this.$refs.wrapper.offsetWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
$--item-size: 132px;

.app-image-grid {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__bottom {
    height: 0;
    width: 100%;
  }

  &__bottom-text {
    font-size: 14px;
    line-height: 16px;
    position: relative;
    height: 48px;
    margin-bottom: -48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@supports (display: grid) {
  .app-image-grid {
    &__container {
      display: grid;
      grid-column-gap: $--clb-space-3;
      grid-row-gap: $--clb-space-3;

      /* autoprefixer: ignore next */
      grid-template-columns: repeat(auto-fill, minmax($--item-size, auto));
    }

    &__item {
      min-width: 0;
      width: auto;
    }
  }
}
</style>
