<template>
  <div class="campaign-setup-marketing-info">
    <div class="campaign-setup-marketing-info-wrapper tw-pb-layout-3 tw-px-layout-1 sm:tw-px-layout-2 md:tw-px-layout-3">
      <section class="campaign-setup-marketing-info__overview">
        <div class="tw-flex tw-items-center tw-justify-between">
          <app-heading weight="semi-bold" level="h3" class="tw-text-jb-ink sm:tw-text-2xl tw-text-xl">
            Offer Details
          </app-heading>
          <span class="tw-mb-layout-1 tw-text-jb-grey-700 tw-flex tw-items-end tw-cursor-pointer" @click="copySummary"><LinkIcon class="tw-mr-space-1" />Copy to Clipboard</span>
        </div>

        <app-icon-card>
          <template #desc>
            <div class="tw-text-jb-grey-700 tw-p-space-1 tw-font-medium tw-whitespace-pre-line">
              {{ campaign.description ? campaign.description : 'N/A' }}
            </div>
          </template>
        </app-icon-card>

        <div v-if="campaign.pairing_id" class="tw-mt-space-4 tw-p-space-4 tw-bg-white tw-rounded">
          <h6 class="tw-mb-space-4 tw-mt-0">
            Upsell Offer
          </h6>
          <span class="tw-text-jb-grey-700 tw-p-space-1 tw-font-medium">Step {{ { 1: 2, 2: 1 }[campaign.step] }}: <a class="tw-font-normal tw-underline" @click="navigateToUpsellOffer(campaign.pairing_id)">{{ campaign.pairing_name }}</a></span>
        </div>
      </section>
      <section v-if="isApplicationApproved && showCampaignReportingSection" class="campaign-setup-reporting">
        <app-heading weight="semi-bold" level="h3" class="tw-text-jb-ink sm:tw-text-2xl tw-text-xl">
          Performance
        </app-heading>
        <app-icon-card>
          <template #desc>
            <campaign-reporting />
          </template>
        </app-icon-card>
      </section>
      <template v-if="isApplicationApproved">
        <section class="campaign-setup-marketing-info__notice">
          <app-heading weight="semi-bold" level="h3" class="tw-text-jb-ink sm:tw-text-2xl tw-text-xl">
            Terms & Conditions
          </app-heading>
          <app-icon-card>
            <template #desc>
              <div class="tw-p-space-2">
                <div v-for="(metric, key) in termsAndConditions" :key="`metric-item-${key}`" class="tw-pb-space-4">
                  <app-heading weight="semi-bold" level="h5" class="tw-text-xl tw-text-black">
                    {{ metric.name }}
                  </app-heading>
                  <div class="tw-text-jb-grey-700">
                    {{ metric.value }}
                  </div>
                </div>
              </div>
            </template>
          </app-icon-card>
        </section>
      </template>
      <section class="campaign-setup-marketing-info__examples">
        <div class="tw-flex tw-justify-between">
          <app-heading weight="semi-bold" level="h3" class="tw-text-jb-ink sm:tw-text-2xl tw-text-xl">
            Preview
          </app-heading>
          <portal-target name="campaign-preview-view-all" />
        </div>
        <campaign-image-grid :campaign-id="campaignId" />
      </section>
      <section v-if="isAffiliate" class="campaign-setup-marketing__similar-list tw-w-full">
        <app-heading weight="semi-bold" level="h3" class="tw-text-jb-ink">
          <span class="xs:tw-inline tw-hidden">Similar </span>Campaigns
        </app-heading>
        <template v-if="fetchingCampaignInspirations || campaignInspirations.length">
          <app-glide :items="fetchingCampaignInspirations ? new Array(8): campaignInspirations" :loading="fetchingCampaignInspirations" @item-click="handleViewCampaign">
            <div slot-scope="row" class="tw-mr-layout-1">
              <campaign-card-v2-skeleton v-if="fetchingCampaignInspirations" class="!tw-w-40" />
              <campaign-card-v2 v-else class="!tw-w-40 tw-h-52" :campaign="row.item" />
            </div>
          </app-glide>
        </template>
        <app-icon-card v-else>
          <template #desc>
            <div class="tw-p-layout-3 tw-flex tw-justify-center">
              No Similar Campaigns.
            </div>
          </template>
        </app-icon-card>
      </section>
    </div>
  </div>
</template>

<script>
import _pick from 'lodash/pick';
import CampaignImageGrid from '@/components/campaigns/CampaignImageGrid';
import CampaignCardV2 from '@/components/campaigns/CampaignCardV2';
import CampaignCardV2Skeleton from '@/components/skeletons/campaigns/CampaignCardV2Skeleton';
import CopyToClipboardService from '@/services/copy-to-clipboard-service';
import AppHeading from '@/components/AppHeading.vue';
import AppIconCard from '@/components/AppIconCard.vue';
import AppGlide from '@/components/AppGlide';
import CampaignReporting from '@/components/campaigns/CampaignReporting';
import LinkIcon from '@/assets/svg/link-icon.svg';
import { AFFILIATE, ADVERTISER } from '@/constants/orgTypes';

export default {
  name: 'CampaignSetupMarketingInformation',
  components: {
    AppHeading,
    CampaignImageGrid,
    CampaignCardV2,
    CampaignCardV2Skeleton,
    AppIconCard,
    AppGlide,
    CampaignReporting,
    LinkIcon
  },
  data() {
    return {
      fetchingCampaignInspirations: false,
      currentPage: 1,
      totalPages: 0,
      isLoading: true
    };
  },
  computed: {
    campaignFilters() {
      return this.$store.getters.campaignFilters;
    },
    orgId() {
      return this.$store.getters.organization.id;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    campaignId() {
      return this.$route.params.id;
    },
    campaign() {
      return this.$store.getters.campaign(this.campaignId) || {};
    },
    countries() {
      const conversionMap = { '00': 'All Countries', '01': 'English International' };
      return (this.campaign.countries || []).slice(0).map((c) => conversionMap[c] || c);
    },
    campaignInspirations() {
      return this.$store.getters.campaignInspirations(this.campaignId);
    },
    isApplicationApproved() {
      return this.campaign && this.campaign.approval_status === 'approved';
    },
    showSkeletons() {
      return !this.campaign || !this.campaign.id;
    },
    termsAndConditions() {
      const terms = [];
      if (this.campaign.terms) {
        terms.push({ name: 'Restrictions', value: this.campaign.terms });
      }
      terms.push({
        name: 'Prohibited Traffic',
        value:
          'Incentivized, Unauthorized Endorsements, Unauthorized 3rd Party Trademarks, Survey, Trademark Bidding, Product Name Bidding, SEO.'
      });
      if (this.countries.length) {
        terms.push({
          name: 'Allowed Countries',
          value: this.countries.join(', ')
        });
      }

      return terms;
    },
    hasCampaignFilters() {
      return Object.entries(this.campaignFilters || {}).length > 0;
    },
    showCampaignReportingSection() {
      return [AFFILIATE, ADVERTISER].includes(this.$store.getters.userType);
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      handler() {
        if (this.isAffiliate) {
          this.isLoading = true;
          this.fetchInspirations(1);
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        }
      }
    }
  },
  methods: {
    handleViewCampaign(campaign) {
      window.scrollTo({ top: 0 });
      this.$router.push({ name: 'dashboard-campaign-setup-marketing', params: { id: campaign.id } });
    },
    fetchInspirations(page) {
      this.fetchingCampaignInspirations = true;
      this.$api.campaigns
        .inspirations({
          orgId: this.orgId,
          campaignId: this.campaignId,
          params: {
            page,
            perPage: 50
          },
          storeAction: 'fetchInspirations'
        })
        .then((response) => {
          this.fetchingCampaignInspirations = false;
          this.totalPages = response._meta.page_count;
          this.currentPage = response._meta.page;
        })
        .catch((e) => {
          this.fetchingCampaignInspirations = false;
          throw e;
        });
    },
    /* eslint-disable */
    getSummaryString() {
      const { step, pairing_id, assets_folder_url, product_shot, main_redirector } = this.campaign;
      const countries = (this.countries && this.countries.join(', ')) || 'N/A';
      const stepOne = _pick(this.campaign, ['id', 'name', 'cpa']);
      const stepTwo = _pick(this.campaign, ['pairing_id', 'pairing_name', 'pairing_cpa']);

      const isSingleStep = !pairing_id;
      const getStepValue = (key) => {
        return `${
          (isSingleStep
            ? step === '1'
              ? stepOne[key]
              : stepTwo[`pairing_${key}`]
            : `${stepOne[key]} / ${stepTwo[`pairing_${key}`]}`) || 'N/A'
        }`;
      };
      return (
        `
Step ${isSingleStep ? step : '1 / Step 2'}
ID: ${getStepValue('id')}
Name: ${getStepValue('name')}
Countries: ${countries}
CPA: ${getStepValue('cpa')}
${product_shot ? `Product Shot: ${product_shot}\n` : ''}` +
        `Assets: ${assets_folder_url}
Link: ${main_redirector}`
      );
      /* eslint-enable camelcase */
    },
    copySummary() {
      CopyToClipboardService.copy(this.getSummaryString())
        .then((val) => {
          this.$message({
            showClose: true,
            message: 'Copied Campaign Summary to Clipboard'
          });
        })
        .catch((e) => {
          this.$message({
            message: 'There was an error copying the Campaign Summary.',
            type: 'error'
          });
        });
    },
    navigateToUpsellOffer(id) {
      const path = this.$route.fullPath.replace(this.campaignId, id);
      this.$router.push(path);
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-marketing-info {
  &-wrapper {
    @media (min-width: 2200px) {
      @apply tw-max-w-screen-2xl tw-mx-auto;
      @apply tw-px-0 #{!important};
    }
  }

  &__header {
    background: linear-gradient(180deg, rgba(64, 128, 225, 0.2) 0%, rgba(248, 249, 253, 1) 100%);
  }

  section {
    @apply tw-mb-10;

    &:last-child {
      @apply tw-mb-0;
    }
  }
}
</style>
