<template>
  <div>
    <slot />
    <app-icon-card>
      <template #desc>
        <app-image-grid
          :show-skeletons="!isFinishedLoading"
          :images="campaignAssets"
          :batch-limit="6"
          @click-image="onClickImage"
          @start-load="handleLoadingState(false)"
          @finish-load="handleLoadingState(true)" />
      </template>
    </app-icon-card>
    <app-lightbox
      v-if="lightboxVisible && lightboxImage"
      :is-visible="lightboxVisible"
      :image-url="lightboxImage"
      @close="lightboxVisible = false"
      @navigation="handleNavigation" />
  </div>
</template>

<script>
import AppImageGrid from '@/components/AppImageGrid';
import AppLightbox from '@/components/AppLightbox';
import AppIconCard from '@/components/AppIconCard.vue';

export default {
  name: 'CampaignImageGrid',
  components: {
    AppImageGrid,
    AppIconCard,
    AppLightbox
  },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lightboxVisible: false,
      lightboxImageIndex: 0,
      isFinishedLoading: false
    };
  },
  computed: {
    isFetchingAssets() {
      return this.$store.getters.fetchingCampaignAssets(this.campaignId);
    },
    campaignAssets() {
      return this.$store.getters.campaignAssets(this.campaignId) || new Array(6);
    },
    campaignHasAssets() {
      return this.$store.getters.campaignHasAssets(this.campaignId);
    },
    lightboxImage() {
      return this.campaignAssets[this.lightboxImageIndex].url;
    },
    orgId() {
      return this.$store.getters.organization.id;
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!this.campaignHasAssets) this.getAssets();
      }
    }
  },
  methods: {
    async getAssets() {
      await this.$api.campaigns.assets({
        orgId: this.orgId,
        campaignId: this.campaignId,
        storeAction: 'fetchCampaignAssets'
      });
    },
    onClickImage(image, index) {
      this.lightboxImageIndex = index;
      this.lightboxVisible = true;
    },
    handleNavigation(direction) {
      const index = this.lightboxImageIndex + direction;
      this.lightboxImageIndex = index < 0 ? this.campaignAssets.length - 1 : index % this.campaignAssets.length;
    },
    handleLoadingState(state) {
      this.isFinishedLoading = state;
    }
  }
};
</script>
