<template>
  <div class="metrics-table">
    <el-table
      ref="table"
      :key="`table-${activeGroupBy}`"
      height="300px"
      :data="activeGroupData"
      header-row-class-name="metric-table-header"
      size="small"
      class="metrics-table--data"
      stripe>
      <el-table-column
        :sortable="!loading"
        :sort-by="activeGroupBy"
        :min-width="120"
        :label="`${$options.filters.capitalizeAll(activeGroupBy.replace('_', ' '))}`"
        fixed>
        <template slot-scope="scope">
          <skeleton-box
            v-if="loading"
            width="128px"
            height="12px"
            style="float: left;" />
          <span v-else>{{ scope.row[activeGroupBy] || scope.row[activeGroupBy + '_name'] || scope.row[activeGroupBy + '_id'] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="metric in metrics"
        :key="`${activeGroupBy}-${metric}-${loading}`"
        :prop="`${metric}`"
        :sort-by="metric"
        :sortable="metric !== 'id' && !loading"
        :sort-orders="['descending', 'ascending', null]"
        :label="columnLabel(metric)">
        <template slot-scope="scope">
          <skeleton-box
            v-if="loading"
            width="64px"
            height="12px"
            style="float: right;" />
          <span v-else>{{ scope.row.formatted_values[metric] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import StickyHeaderMixin from '@/mixins/StickyHeaderMixin';
// configure language
locale.use(lang);

export default {
  name: 'MetricsTable',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    SkeletonBox
  },
  mixins: [StickyHeaderMixin],
  props: {
    details: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    skeletonRows: {
      type: Number,
      default: 7
    },
    metrics: {
      type: Array,
      required: true
    },
    activeGroupBy: {
      type: String,
      required: true
    },
    stickySinkId: {
      type: String,
      default: 'metrics-table-sink'
    },
    stickyAppHeaderHeight: {
      type: Number,
      default: 60 + 48
    },
    moneyLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    activeGroupData() {
      return !this.loading ? this.details[this.activeGroupBy] : new Array(this.skeletonRows).fill(this.loaderRow);
    },
    loaderRow() {
      return [this.activeGroupBy, ...this.metrics].reduce((rows, key) => {
        rows[key] = null;
        return rows;
      }, {});
    }
  },
  watch: {
    activeGroupBy: {
      immediate: true,
      handler() {
        this.addChangeListener();
      }
    }
  },
  methods: {
    columnLabel(metric) {
      if (metric === 'money') {
        return this.moneyLabel;
      }
      return this.$options.filters.capitalizeAll(metric.replace('conversion_rate', 'conv. rate').replace('epc', 'EPC').replace('_', ' '));
    }
  }
};
</script>

<style lang="scss">
.metrics-table {
  &.el-table,
  .el-table,
  .el-table__fixed,
  &.el-table__fixed {
    &::before {
      display: none;
    }

    &__fixed {
      box-shadow: none;

      &-header-wrapper,
      &-body-wrapper {
        z-index: auto;
      }
    }

    .el-table__body-wrapper {
      min-height: 160px;
      overscroll-behavior-x: none;
    }

    .el-table__empty-text {
      line-height: 160px;
    }

    .cell {
      color: $--clb-body-font;
      font-weight: 400;
      padding: 0 6px;
      font-size: 12px;
      line-height: 12px;
      overflow: hidden;
      text-overflow: ellipsis;

      .skeleton-box {
        display: block;
      }
    }

    td,
    th {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }

    thead {
      padding: $--clb-space-4;

      th {
        padding: 6px 0;

        .cell {
          font-weight: 700;
          white-space: nowrap;
          padding-right: 18px;
          position: relative;
          overflow: visible;
          overflow-x: hidden;
          line-height: 34px;
        }
      }

      th:not(:first-child) {
        .cell {
          padding-right: 18px;
        }

        .caret-wrapper {
          width: 18px;
          margin-left: 4px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    tbody {
      tr {
        transition: 0.3s ease;

        .cell {
          padding: 6px;
        }

        td:first-child .cell {
          color: #262626;
          font-weight: 700;
        }

        &:hover .cell {
          color: #262626;
        }
      }
    }
  }

  @media (max-width: 550px) {
    .el-table__fixed {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.app-header__portal {
  .metrics-table {
    position: relative;
    width: 100%;
    padding: 0 $--clb-mobile-padding;

    .el-table__fixed {
      left: $--clb-mobile-padding;
      padding-left: $--clb-mobile-padding;
      box-shadow: none !important;
    }

    th.is-leaf,
    td {
      border-bottom: none;
    }
  }
}

@media (min-width: $--sm) {
  .app-header__portal {
    .metrics-table {
      padding: 0 $--clb-layout-4 0 $--clb-layout-4;
      margin: 0 auto;

      .el-table__fixed {
        left: $--clb-layout-4;
      }
    }
  }
}

@media (min-width: $--md) {
  .app-header__portal {
    .metrics-table {
      padding: 0 $--clb-layout-2 0 $--clb-layout-2;
    }
  }
}

@media (min-width: $--lg) {
  .app-header__portal {
    .metrics-table {
      padding: 0 $--clb-layout-4 0 $--clb-layout-4;
    }
  }
}
</style>
<style lang="scss">
.jb-mobile-device {
  .el-table__row {
    pointer-events: none;
  }
}
</style>
