<template>
  <skeleton-box
    v-if="isFirstLoad"
    class="reporting-overview reporting-overview__skeleton"
    width="100%" />
  <performance-metrics-visualization
    v-else
    v-loading="loadingOverview"
    :overview-data="overviewData"
    :metric-key="metricKey"
    class="reporting-overview reporting-overview__visualization"
    @handle-metric-change="handleMetricChange" />
</template>

<script>
import PerformanceMetricsVisualization from '@/components/visualizations/PerformanceMetricsVisualization';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'ReportingOverview',
  components: { PerformanceMetricsVisualization, SkeletonBox },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      overviewData: undefined,
      loadingOverview: true,
      isFirstLoad: true
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    metricKey() {
      return this.$store.getters.getMetricKey;
    },
    dateOptions() {
      return this.$store.getters.getMetricDateOptions;
    },
    showReportingMetricsVisualizationSkeleton() {
      return !this.isActivePage || this.isFirstLoad;
    }
  },
  watch: {
    dateOptions() {
      this.fetchReportingCampaignOverview();
    },
    campaignId(){
      this.isFirstLoad = true;
      this.fetchReportingCampaignOverview();
    }
  },
  mounted() {
    this.fetchReportingCampaignOverview();
  },
  methods: {
    fetchReportingCampaignOverview() {
      this.loadingOverview = true;
      this.$store
        .dispatch('fetchReportingCampaignOverview', {
          orgId: this.orgId,
          campaignId: this.campaignId,
          options: this.dateOptions
        })
        .then(response => {
          this.overviewData = Object.freeze(response);
          this.loadingOverview = this.isFirstLoad = false;
        });
    },
    handleMetricChange(newMetricKey) {
      if (newMetricKey !== this.metricKey) {
        this.$store.dispatch('setMetricKey', { metricKey: newMetricKey });
      }
    }
  }
};
</script>

<style lang="scss">
.reporting-overview {
  &__skeleton {
    height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-xs});
  }

  &__visualization {
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding-bottom: $--clb-layout-2;
  }
}

@media (min-width: $--xs) {
  .reporting-overview {
    &__skeleton {
      height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-sm});
    }
  }
}

@media (min-width: $--sm) {
  .reporting-overview {
    &__skeleton {
      height: 360px;
    }

    &__visualization {
      padding: $--clb-layout-2;
    }
  }
}
</style>
