<template>
  <div>
    <campaign-metrics
      :user-type="userType"
      :loading="loadingMetrics"
      :details="campaignDetails"
      :org-type="org.type" />
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import CampaignMetrics from '@/components/campaigns/CampaignMetrics';

export default {
  name: 'ReportingTable',
  components: { SkeletonBox, CampaignMetrics },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingMetrics: false,
      campaignDetails: {},
      currentPage: 1
    };
  },
  computed: {
    org() {
      return this.$store.getters.organization;
    },
    dateOptions() {
      return this.$store.getters.getMetricDateOptions;
    },
    userType() {
      return this.$store.getters.userType;
    }
  },
  watch: {
    dateOptions() {
      this.fetchReportingCampaignMetrics();
    },
    campaignId(){
      this.fetchReportingCampaignMetrics();
    }
  },
  mounted() {
    this.fetchReportingCampaignMetrics();
  },
  methods: {
    fetchReportingCampaignMetrics() {
      this.loadingMetrics = true;
      this.$store
        .dispatch('fetchReportingCampaignMetrics', {
          campaignId: this.campaignId,
          orgId: this.org.id,
          options: {
            page: this.currentPage,
            ...this.dateOptions
          }
        })
        .then(response => {
          this.campaignDetails = Object.freeze(response);
          this.loadingMetrics = false;
          const noCsvData = !Object.keys(response).find(k => response[k].length);
          this.$emit('campaign-details-change', false, noCsvData);
        });
    }
  }
};
</script>
