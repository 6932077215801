<template>
  <div class="tw-p-space-4 campaign-card-v2 tw-w-full tw-bg-white tw-border tw-rounded" @click="$emit('click')">
    <div class="image-wrapper tw-flex tw-justify-center">
      <app-image class="tw-border tw-rounded" width="150" height="150" :src="campaign.image_desktop" />
    </div>
    <div>
      <app-heading weight="semi-bold" level="h5" size="default" class="tw-text-jb-ink tw-mt-space-3 tw-mb-0 tw-truncate">
        {{ campaign.name }}
      </app-heading>
      <app-text weight="medium" type="div" size="xs" class="tw-text-jb-grey-700 tw-mt-space-2 tw-capitalize tw-truncate">
        {{ campaign.vertical || 'N/A' }}
      </app-text>
    </div>
  </div>
</template>

<script>
import AppImage from '@/components/AppImage';
import AppText from '@/components/AppText';
import AppHeading from '@/components/AppHeading';

export default {
  name: 'CampaignCardV2',
  components: {
    AppImage,
    AppHeading,
    AppText
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang="scss">
.campaign-card-v2 {
  .image-wrapper {
    height: 150px;

    .app-image {
      img {
        @apply tw-rounded;
      }
    }
  }
}
</style>
